@import 'styles/ui/variables/variables';
@import 'styles/ui/base/variables';
@import 'styles/ui/variables/theme';
@import 'styles/ui/mixins/svg';

.payhoa-selection-list,
.payhoa-selection-list-builder {
    .mat-selection-header {
        align-items: center;
        background-color: $white;
        color: $colortextfeature;
        display: flex;
        font-family: $fontsemibold;
        font-size: 16px;
        font-weight: 500;
        justify-content: space-between;
        line-height: 1.55;
        height: 50px;
        padding: 0 16px;
    }

    .selection-list-header {
        background: $lightgray;
        border-top: 1px solid $offsetgray;
        border-bottom: 1px solid $offsetgray;
        color: $text-table-header;
        display: flex;
        font-family: $fontreg;
        font-size: 12px;
        letter-spacing: 0.03rem;
        line-height: 1;
        text-transform: uppercase;
        white-space: nowrap;
        width: 100%;

        > span {
            &:not(.list-builder-icon) {
                padding: 15px;
            }
        }
    }
}

.payhoa-selection-list {
    .mat-selection-list {
        max-height: 350px;
        min-height: 200px;
        overflow-x: auto;
        overflow-y: auto;
        padding: 0;

        .mat-list-item {
            background-color: $white;

            .mat-pseudo-checkbox {
                border-width: 1px !important;
                margin-right: 15px;

                &:after {
                    top: -3px;
                    width: 10px;
                    height: 5px;
                }

                &:not(.mat-pseudo-checkbox-disabled) {
                    &.mat-pseudo-checkbox-checked,
                    &.mat-pseudo-checkbox-indeterminate {
                        background-color: #53C1A9;
                    }
                }

                &.mat-pseudo-checkbox-disabled {
                    background-color: #B0B0B0;
                }
            }

            .mat-list-text {
                padding: 15px 0 !important;
            }
        }

        .mat-list-option {
            color: $colortextdark;
            font-family: $fontreg;
            font-size: 14px;
            height: auto;
            line-height: 1.2;
        }

        .mat-divider {
            border-top-color: $offsetgray;
            border-top-style: dashed;
        }
    }
}

.payhoa-selection-list-builder {
    display: flex;
    justify-content: space-between;
    min-height: 425px;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
    overflow-x: auto;
    width: 100%;
    will-change: transform;
}

.payhoa-selection-list-builder-left,
.payhoa-selection-list-builder-right {
    background-color: $lightgray;
    display: flex;
    flex-direction: column;
    width: 100%;

    .cdk-virtual-scroll-viewport {
        height: 385px;
        width: 100%;
    }

    .list-builder-header {
        .list-builder-column {
            width: calc(40% - 1rem);

            + .list-builder-column {
                width: calc(60% - 1rem);
            }
        }
    }

    .list-builder-icon {
        background: rgba(#DAEEFC, 0.2);
        align-items: center;
        display: flex;
        font-size: 1.5rem;
        justify-content: center;
        margin: auto;
        width: 2rem;
    }

    .list-builder-column {
        display: flex;
        flex-direction: column;
    }

    .mat-list-item {
        .mat-list-item-content {
            padding: 0 !important;
        }

        &:hover,
        &:focus {
            background-color: #F2F9FE !important;

            .mat-icon {
                @include svg-blue-link-filter();
            }
        }
    }

    .mat-selection-list {
        padding: 0;

        .mat-pseudo-checkbox {
            display: none !important;
            opacity: 0 !important;
        }

        .mat-list-option {
            color: $colortextdark;
            font-family: $fontreg;
            font-size: 14px;
            height: auto;
            line-height: 1.2;

            .mat-ripple-element {
                background: rgba(#1676BB, 0.2);
                padding-top: 15px;
                padding-bottom: 15px;
            }

            .mat-list-text {
                display: flex;
                flex-direction: row;
                height: 100%;
                justify-content: space-between;
                padding: 0 !important;
                width: 100%;

                .list-builder-column {
                    padding: 15px;
                    width: calc(40% - 1rem);

                    + .list-builder-column {
                        width: calc(60% - 1rem);
                    }
                }
            }

            .amount {
                font-family: $fontsemibold;
                font-size: 16px;
                font-weight: 500;
                padding-top: 0.125rem;
            }

            .note-text {
                color: $colordescription;
                font-family: $fontsemibold;
                font-weight: 500;
                padding-top: 0.125rem;
            }
        }
    }

    .mat-divider {
        border-top-color: $offsetgray;
        border-top-style: dashed;
    }
}

.payhoa-selection-list-builder-left {
    .mat-list-item {
        &:not(:hover):not(:focus) {
            background-color: $white;
        }
    }
}

.payhoa-selection-list-builder-right {
    border-left: 1px solid $offsetgray;
}
